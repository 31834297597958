import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import React from 'react'

import App from '../src/client/components/App'
import Newsletter from '../src/client/components/Newsletter'
import '../src/client/styles.css'

export const cache = createCache({ key: 'css' })

export default function MyApp(props: AppProps) {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles)
    }
  }, [])

  return (
    <CacheProvider value={cache}>
      <Head>
        <title>Joga</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <App {...props} />
      <Newsletter />
    </CacheProvider>
  )
}
