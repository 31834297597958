import { useState, useCallback } from 'react'

const useBoolean = (
  initialValue: boolean
): [
  boolean,
  {
    setBool: (bool: boolean) => void
    toggle: () => void
    setTrue: () => void
    setFalse: () => void
  }
] => {
  const [bool, setBool] = useState(initialValue)

  const toggle = useCallback(() => {
    setBool(!bool)
  }, [bool])

  const setTrue = useCallback(() => {
    setBool(true)
  }, [])

  const setFalse = useCallback(() => {
    setBool(false)
  }, [])

  return [bool, { setBool, toggle, setTrue, setFalse }]
}

export default useBoolean
