import styled from '@emotion/styled'
import React, { useState, useEffect } from 'react'

const Overlay = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 2000;
  backdrop-filter: blur(4px);
`

const PopupContainer = styled.div`
  background: var(--main-bg-color, #fff);
  padding: 32px; // Use fixed padding instead of theme.spacing
  border-radius: 8px;
  max-width: 750px;
  width: 90%;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out;
  font-size: 1.1rem; // Increase font size for all text in the popup
  max-height: 80vh; // Limit the height of the popup
  overflow-y: auto; // Make the popup scrollable if content overflows

  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media (max-width: 600px) {
    padding: 16px; // Adjust padding for smaller screens
    font-size: 1rem; // Adjust font size for smaller screens
  }
`

const Title = styled.h3`
  color: var(--main-text-color, #1a1a1a);
  font-size: 1.5rem; // Increase font size for the title
  font-weight: 600;
  margin: 0 0 1rem;
`

const Content = styled.div`
  color: var(--main-text-color, #4a4a4a);
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-size: 1.1rem; // Increase font size for the content

  ul {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: none;
  border: none;
  font-size: 1.75rem; // Increase font size for the close button
  cursor: pointer;
  color: #5aa30e;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(90, 163, 14, 0.1);
  }
`

const DontShowButton = styled.button`
  background: none;
  border: 2px solid #5aa30e;
  color: #5aa30e;
  font-size: 1rem; // Increase font size for the "Don't Show Again" button
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.2s;

  &:hover {
    background-color: #5aa30e;
    color: white;
  }
`

const Newsletter = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const hasHiddenNewsletter = localStorage.getItem('hideNewsletter')
    if (!hasHiddenNewsletter) {
      // Show popup after 2 seconds
      const timer = setTimeout(() => setIsVisible(true), 2000)
      return () => clearTimeout(timer)
    }
  }, [])

  const handleClose = () => {
    setIsVisible(false)
  }

  const handleDontShowAgain = () => {
    localStorage.setItem('hideNewsletter', 'true')
    setIsVisible(false)
  }

  return (
    <Overlay isVisible={isVisible}>
      <PopupContainer>
        <CloseButton onClick={handleClose}>&times;</CloseButton>
        <Title>Novidades da Plataforma</Title>
        <Content>
          <p>
            🎉 Estamos muito animados para compartilhar as últimas melhorias na
            Joga:
          </p>
          <ul style={{ marginLeft: '1.5rem', marginTop: '0.5rem' }}>
            <li>
              🎨 <b>Estilos atualizados:</b> deixamos a plataforma ainda mais
              moderna e agradável de usar.
            </li>
            <li>
              🚀 <b>Melhorias de performance:</b> a plataforma está mais rápida
              e eficiente, garantindo uma experiência ainda mais fluida e sem
              interrupções.
            </li>
            <li>
              📅 <b>Cabeçalho com menu rápido:</b> agora ficou mais fácil
              navegar pelas principais funcionalidades!
            </li>
            <li>
              🔄 <b>Nova tela de calendários:</b> uma nova interface para
              gerenciar seus eventos e treinos de forma ainda mais prática.
            </li>
            <li>
              ⏰ <b>Sistema de ponto eletrônico:</b> registre as entradas e
              saídas dos seus colaboradores com eficiência.
            </li>
            <li>
              🛠️ <b>Melhoria na tela de presenças:</b> acompanhamento mais ágil
              e organizado da frequência dos atletas.
            </li>
            <li>
              🚫 <b>Regras e limitadores nos grupos:</b> mantenha a organização
              e controle sobre os grupos de forma simples.
            </li>
            <li>
              💰 <b>Tela de fluxo de caixa (beta):</b> visualize e acompanhe
              suas finanças com mais clareza.
            </li>
            <li>
              💳 <b>Gestão de pagamentos melhorada:</b> gerencie os pagamentos
              com mais facilidade e controle.
            </li>
            <li>
              🔗 <b>Link de pré-cadastro para atletas:</b> facilite o cadastro
              dos novos atletas com um link de fácil acesso.
            </li>
          </ul>
          <p style={{ marginTop: '1rem' }}>
            🤔 Se tiver dúvidas, ou quiser saber mais das novidades, estamos à
            disposição! Entre em contato com nosso suporte em
            <b> +55 48 9154-9417</b>.
          </p>
          <p>Conte conosco para ajudar! 📞</p>
        </Content>
        <DontShowButton onClick={handleDontShowAgain}>
          Vamos nessa! ⚽️
        </DontShowButton>
      </PopupContainer>
    </Overlay>
  )
}

export default Newsletter
